.App {
  font-family: CeliciaGarcia;
  text-align: center;
}

.puzzle {
  text-align: center;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.grid {
  display: grid;
  grid-template-columns: auto auto auto auto;
  width: 500px;
  height: 500px;
  background-color: rgb(166, 218, 149);
  border-radius: 10px;
  position: relative;
  padding-left: 20px;
  padding-top: 20px;
}

.tile {
  background-color: rgba(0, 0, 0, 0.11);
  width: 103px;
  height: 103px;
  padding: 0;
  border: 1px solid gray;
  border-radius: 10px;
  width: fit-content;
  height: fit-content;
}

.inner-tile,
.inner-tile-empty {
  display: flex;
  justify-content: center;
  background-color: rgba(255, 255, 165, 1);
  align-items: center;
  cursor: pointer;
  width: 103px;
  height: 103px;
  border-radius: 10px;
  font-size: 30pt;
}

.inner-tile-empty {
  background-color: transparent;
}

.btn-div {
  display: flex;
  justify-content: space-between;
  width: 300px;
}

.btn-style {
  width: 90px;
  height: 50px;
  margin-top: 20px;
  background-color: rgb(18, 73, 7, 0.17);
  border-radius: 5px;
  border: none;
  font-size: 15pt;
  cursor: pointer;
}

.overlay {
  position: absolute;
  width: 500px;
  height: 500px;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: rgba(0, 0, 0, 0.7);
  font-size: 106px;
  color: white;
  cursor: pointer;
  padding-left: 20px;
  padding-top: 20px;
  border-radius: 10px;
}

.stop-div {
  border-radius: 10px;
  background-color: rgba(158, 170, 255, 0.37);
  width: 110px;
  margin-top: 20px;
}
